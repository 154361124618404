import React, { useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';

function Edit(props) {
  const { record, onSave, onCancel } = props;
  const initRecord = record || {
    model: '',
    price: 0,
    description: '',
  };

  const [model, setModel] = useState(initRecord.model);
  const [price, setPrice] = useState(initRecord.price);
  const [description, setDescription] = useState(initRecord.description);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    onSave({
      ...record,
      model,
      price,
      description,
    });
  };

  const cancelButton = (
    <Button
      variant='secondary'
      onClick={handleCancel}
    >
      Cancel
    </Button>
  );

  return (
    <section>
      <h2>Enter phone detail</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Form.Label>Model</Form.Label>
          <Form.Control
            value={model}
            onChange={(e) => setModel(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Form.Label>Price</Form.Label>
          <Form.Control
            type='number'
            step='50'
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Form.Label>What&apos;s special about this phone?</Form.Label>
          <Form.Control
            as='textarea'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
        <Button
          variant='primary'
          type='submit'
        >
          Save
        </Button>
        {' '}
        {cancelButton}
      </Form>
    </section>
  );
}

function View(props) {
  const { phone } = props;
  return (
    <>
      <h2>Phone</h2>
      Model:
      {phone.model}
      <br />
      Price: $
      {phone.price}
      <br />
    </>
  );
}

const PhoneDetail = {
  Edit,
  View,
};

export { PhoneDetail };
