import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function View(props) {
  const { plan } = props;
  if (!plan) {
    return null;
  }

  let upfront = null;
  if (plan.phoneUpfront) {
    upfront = (
      <span>
        One time upfront =
        {' '}
        {(+plan.phoneUpfront).toFixed(2)}
      </span>
    );
  }

  let monthlyTab = null;
  if (plan.phoneMonthly) {
    monthlyTab = (
      <span>
        {(+plan.phoneMonthly).toFixed(2)}
        {' '}
        * 24 months =
        {' '}
        {(+plan.phoneMonthly * 24).toFixed(2)}
      </span>
    );
  }

  return (
    <section className='plan-detail'>
      <span className='main'>{plan.name}</span>
      <span>
        {(+plan.price).toFixed(2)}
        {' '}
        * 24 months =
        {' '}
        {(+plan.price * 24).toFixed(2)}
      </span>
      {upfront}
      {monthlyTab}
    </section>
  );
}

function Edit(props) {
  const { onSave, plan } = props;

  const initPlan = plan || {
    name: '',
    price: 0,
    phoneUpfront: 0,
    phoneMonthly: 0,
  };

  const [name, setName] = useState(initPlan.name);
  const [price, setPrice] = useState(initPlan.price);
  const [phoneUpfront, setPhoneUpfront] = useState(initPlan.phoneUpfront);
  const [phoneMonthly, setPhoneMonthly] = useState(initPlan.phoneMonthly);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    onSave({
      ...plan,
      name,
      price,
      phoneUpfront,
      phoneMonthly,
    });
  };

  let header = null;

  if (plan) {
    header = <h2>Edit Plan</h2>;
  } else {
    header = <h2>Add Plan</h2>;
  }

  return (
    <section>
      {header}
      <Form onSubmit={handleOnSubmit}>
        <Form.Group>
          <Form.Label>Plan name</Form.Label>
          <Form.Control
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Monthly Plan Price</Form.Label>
          <Form.Control
            type='number'
            value={price}
            onChange={(event) => setPrice(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Upfront price</Form.Label>
          <Form.Control
            type='number'
            value={phoneUpfront}
            onChange={(event) => setPhoneUpfront(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Monthly Tab (or, interest free loan payment)</Form.Label>
          <Form.Control
            type='number'
            value={phoneMonthly}
            onChange={(event) => setPhoneMonthly(event.target.value)}
          />
        </Form.Group>
        <Button variant='primary' type='submit'>
          Save
        </Button>
      </Form>
    </section>
  );
}

const PlanDetail = {
  View,
  Edit,
};

export { PlanDetail };
