import React, { useState } from 'react';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { PhonePlanTable } from './PhonePlanTable';
import { PlanDetail } from './PlanDetail';
import { loadPlans, savePlans } from './planStore';

function PhonePlanController(props) {
  const { phone } = props;
  const [plans, setPlans] = useState(loadPlans());
  const [editID, setEditID] = useState(-1);

  let id = 0;

  if (plans.length > 0) {
    id = plans[plans.length - 1].id + 1;
  }

  let initialIndex = 0;
  const phonePlans = plans.filter(
    (plan) => plan.phoneID === phone.id,
  );

  if (phonePlans.length > 0) {
    initialIndex = 1;
  }

  const [viewIndex, setViewIndex] = useState(initialIndex);

  const updatePlans = (records) => {
    savePlans(records);
    setPlans(records);
  };

  const handleSavePlan = (plan) => {
    const records = [...plans];

    if (plan.id) {
      const recordIndex = records.findIndex((item) => item.id === plan.id);
      records[recordIndex] = plan;
    } else {
      const newPlan = {
        ...plan,
        id: id++,
        phoneID: phone.id,
      };
      records.push(newPlan);
    }

    updatePlans(records);
    setViewIndex(1);
  };

  const handleAddPlan = () => {
    setEditID(-1);
    setViewIndex(0);
  };

  const handleDeletePlan = (plan) => {
    const records = plans.filter((item) => item.id !== plan.id);
    updatePlans(records);
  };

  const handleModifyPlan = (plan) => {
    setEditID(plan.id);
    setViewIndex(0);
  };

  let editPlan = null;
  if (editID >= 0) {
    editPlan = plans.find((item) => item.id === editID);
  }

  const views = [
    <PlanDetail.Edit
      onSave={handleSavePlan}
      plan={editPlan}
    />,
    <>
      <h2>
        Two year plans -
        {phone.model}
      </h2>
      <p className='header-slogan'>The lower the total pay for two years, the better.</p>
      <p>Columns</p>
      <ul>
        <li>
          Total: Sum of total payment over the two years excluding taxes.
        </li>
        <li>
          Overpay: Difference compared with the best plan in the list.
        </li>
      </ul>
      <PhonePlanTable
        plans={phonePlans}
        listCommands={[
          {
            text: 'Add Plan',
            handler: handleAddPlan,
          },
        ]}
        itemCommands={[
          {
            icon: <PencilFill />,
            text: 'Modify',
            handler: handleModifyPlan,
          },
          {
            icon: <TrashFill />,
            text: 'Delete',
            handler: handleDeletePlan,
          },
        ]}
      />
    </>,
  ];

  return (
    views[viewIndex]
  );
}

function PlanComparison(props) {
  const { phone } = props;

  if (!phone) {
    return null;
  }

  return (
    <>
      <PhonePlanController
        phone={phone}
      />
    </>
  );
}

export { PlanComparison };
