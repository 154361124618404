import React, { useState } from 'react';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { PhoneDetail } from '../PhoneDetail';
import { loadPlans, savePlans } from '../planStore';
import { PhoneList } from './PhoneList';
import { PhonePlanController } from './PhonePlanController';
import { PhoneSmartHome } from './PhoneSamrtHome';

const phoneItemsKey = 'phoneItems';

const savePhones = (records) => {
  const dataStore = window.localStorage;
  dataStore.setItem(phoneItemsKey, JSON.stringify(records));
};

const loadPhones = () => {
  const dataStore = window.localStorage;
  const records = dataStore.getItem(phoneItemsKey);
  try {
    if (records && records.length > 0) {
      return JSON.parse(records);
    }
    return [];
  } catch {
    return [];
  }
};

// PhoneListController controls display of phone list
// and the plans that are associated with the phone
function PhoneListController() {
  const [items, setItems] = useState(loadPhones());
  let initialActiveID = -1;
  if (items.length > 0) {
    initialActiveID = items[0].id;
  }
  const [activeID, setActiveID] = useState(initialActiveID);
  let id = 0;

  if (items && items.length > 0) {
    id = items[items.length - 1].id + 1;
  }

  // enter phone first
  let initialViewIndex = 0;
  if (items.length > 0) {
    initialViewIndex = 1;
  }
  const [viewIndex, setViewIndex] = useState(initialViewIndex);
  const [editID, setEditID] = useState(-1);

  function updateRecords(records) {
    setItems(records);
    savePhones(records);
  }

  const handleSave = (record) => {
    const records = [...items];
    if (record.id) {
      const index = records.findIndex((item) => item.id === record.id);
      records[index] = record;
    } else {
      const newRecord = {
        ...record,
        id: id++,
      };
      records.push(newRecord);
    }

    updateRecords(records);

    setActiveID(record.id);
    setViewIndex(1);
  };

  const onPhoneSelected = (record) => {
    setActiveID(record.id);
  };

  const handleEditPhone = (record) => {
    setEditID(record.id);
    setViewIndex(0);
  };

  const deleteAssociatedRecords = (phoneID) => {
    // delete plans associated with the phone
    const plans = loadPlans();
    savePlans(plans.filter((plan) => plan.phoneID && plan.phoneID !== phoneID));
  };

  const handleDeletePhone = (record) => {
    const records = items.filter((item) => item.id !== record.id);
    if (activeID === record.id) {
      setActiveID(-1);
    }
    updateRecords(records);
    deleteAssociatedRecords(record.id);

    if (records.length === 0) {
      setViewIndex(0);
    }
  };

  const handleAddPhone = () => {
    setEditID(-1);
    setViewIndex(0);
  };

  const findPhoneByID = (phoneID) => items.find((item) => item.id === phoneID);

  let editItem = null;
  let phonePlans = null;
  if (items && items.length > 0) {
    if (editID >= 0) {
      editItem = findPhoneByID(editID);
    }

    if (activeID >= 0) {
      const activePhone = findPhoneByID(activeID);
      if (activePhone) {
        phonePlans = (
          <section className='item-detail'>
            <PhonePlanController phone={activePhone} />
          </section>
        );
      }
    }
  }

  const handleCancelEdit = () => {
    if (items.length > 0) {
      setViewIndex(1);
    }
  };

  const views = [
    <PhoneDetail.Edit
      record={editItem}
      onSave={handleSave}
      onCancel={handleCancelEdit}
    />,
    <section className='phonesmart-main'>
      <PhoneList
        phones={items}
        activeID={activeID}
        onItemSelected={onPhoneSelected}
        itemCommands={[
          {
            text: 'Modify',
            icon: <PencilFill />,
            handler: handleEditPhone,
          },
          {
            text: 'Delete',
            icon: <TrashFill />,
            handler: handleDeletePhone,
          },
        ]}
        listCommands={[
          {
            text: 'Add Phone',
            handler: handleAddPhone,
          },
        ]}
      />
      {phonePlans}
    </section>,
  ];

  return (
    <>
      {views[viewIndex]}
    </>
  );
}

// PhonePlan manages views for comparing plans
function PhonePlans() {
  // get initial view index (help me redux!)
  const phones = loadPhones();
  let initialViewIndex = 0;
  if (phones.length > 0) {
    initialViewIndex = 1;
  }

  // enter phone first
  const [viewIndex, setViewIndex] = useState(initialViewIndex);

  const handleOnStart = () => {
    setViewIndex(1);
  };

  const views = [
    <PhoneSmartHome onStart={handleOnStart} />,
    <PhoneListController />,
  ];

  return (
    views[viewIndex]
  );
}

export { PhonePlans };
