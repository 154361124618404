import React from 'react';
import { Button } from 'react-bootstrap';

function PhoneSmartHome(props) {
  const { onStart } = props;

  return (
    <>
      <p>Let&apos;s see if buying a phone with a plan is a good choice.</p>
      <p>
        Typically phone companies provide discounted phone with minimum plan for devices.
      </p>
      <p>
        Before using the tool, gather the following data to help you compare:
      </p>
      <ul>
        <li>The minimum plan you need. E.g. 60 min talk per month with no data.</li>
        <li>
          Find the price of the phone that you want from a reputable source (e.g. BestBuy).
          Not from phone plan providers as their prices are generally the highest.
        </li>
        <li>
          Find phone plan providers you like, and
          pick the cheapest plan that covers your needs.
        </li>
      </ul>
      <p>
        Please note that in order help you see the big picture:
      </p>
      <ul>
        <li>Taxes are excluded</li>
        <li>Welcome discounts are excluded (You can remove from the phone price)</li>
        <li>
          Cancellation process excluded.
          Cancelling a plan will forfeit the phone discount.
        </li>
      </ul>
      <p>
        Generally, if you are paying for a plan that gives you more than what you need,
        you may get a better deal by having a plan that barely meets your needs
        with buying the phone outright.
        If your provider&apos;s plan just covers what you need
        and they have a phone you want, then it might be a good deal
        if you can complete the contract.
      </p>
      <p>
        Good luck!
      </p>
      <p>
        <Button
          variant='primary'
          onClick={onStart}
        >
          Let&apos;s start!
        </Button>
      </p>
    </>
  );
}

export { PhoneSmartHome };
