const planItemsKey = 'planItems';

const savePlans = (records) => {
  const dataStore = window.localStorage;
  dataStore.setItem(planItemsKey, JSON.stringify(records));
};

const loadPlans = () => {
  const dataStore = window.localStorage;
  const records = dataStore.getItem(planItemsKey);
  try {
    if (records && records.length > 0) {
      return JSON.parse(records);
    }
    return [];
  } catch {
    return [];
  }
};

export { savePlans, loadPlans };
