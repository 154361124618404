/* eslint-disable */

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PhonePlans } from '../components/controllers/PhonePlans';

import { PlanComparison } from '../components/PlanComparison';

function PhoneSmart() {
  const location = useLocation();
  const history = useHistory();

  const { activeIndex } = location.state || { activeIndex: 0 };

  const handleStart = () => {
    history.push(history.location.pathname, { activeIndex: 1 });
  };

  const views = [
    <Intro onStart={handleStart} />,
    <PlanComparison plans={[]} />,
  ];

  return (
    <div>
      <h1>Phone Smart</h1>
      { views[activeIndex] }
    </div>
  );
}

function Intro(props) {
  const handleStart = () => {
    props.onStart();
  };
  return (
    <>
      <PhonePlans />
    </>
  );
}

export { PhoneSmart };
/* eslint-enable */
