import React from 'react';

function Home() {
  return (
    <>
      <h2>
        You aren&apos;t gonna need it
      </h2>
      <p>
        Making a decision is challenging especially when it is a subscription based.
        I believe we can make a good choice when we have uncluttered data.
      </p>
      <p>
        Most places, the tangled data lure you in.
        This gives us a false sense of getting a good deal.
      </p>
      <p>
        This site is to help you focus on the most important parts.
      </p>
      <p>
        Please help yourself with the tools and feel better with the contracts you sign.
      </p>
      <p>
        Remember, tech devices will be cheaper tomorrow.
      </p>
    </>
  );
}

export { Home };
