import React from 'react';
import { Button } from 'react-bootstrap';

// ItemCommands displays items to execute commands
function ItemCommands(props) {
  const {
    className,
    commands,
    item,
  } = props;

  if (!commands || commands.length === 0) {
    return null;
  }

  const buttons = commands.map(
    (command) => (
      <Button
        onClick={() => command.handler(item)}
        variant='secondary'
      >
        {command.icon || command.text}
      </Button>
    ),
  );

  return (
    <div className={className}>
      {buttons}
    </div>
  );
}

export { ItemCommands };
