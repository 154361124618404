import React from 'react';
import {
  Navbar,
  Nav,
} from 'react-bootstrap';
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Home } from '../pages/Home';
import { PhoneSmart } from '../pages/PhoneSmart';

function MainLayout() {
  const routes = {
    home: '',
    phoneSmart: 'phonesmart',
  };

  const match = useRouteMatch();
  const history = useHistory();

  let uri = match.url;
  if (!uri.endsWith('/')) {
    uri += '/';
  }

  const handleNav = (id) => {
    history.push(uri + id);
  };

  return (
    <>
      <nav>
        <Navbar bg='light' expand='lg'>
          <Navbar.Brand onClick={() => handleNav(routes.home)}>YAGNI</Navbar.Brand>
          <Nav className='mr-auto'>
            <Nav.Link onClick={() => handleNav(routes.phoneSmart)}>Phone Smart</Nav.Link>
          </Nav>
        </Navbar>
      </nav>
      <section className='main'>
        <Switch>
          <Route path={`${uri}${routes.phoneSmart}`}>
            <PhoneSmart />
          </Route>
          <Route path={`${uri}${routes.home}`}>
            <Home />
          </Route>
        </Switch>
      </section>
    </>
  );
}

export { MainLayout };
