import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';

function PhoneList(props) {
  const {
    phones,
    activeID,
    onItemSelected,
    listCommands,
    itemCommands,
  } = props;

  if (!phones || phones.length === 0) {
    return null;
  }

  const phoneItems = phones.map(
    (phone) => {
      const isActive = phone.id === activeID;
      let itemButtons = null;
      if (itemCommands) {
        itemButtons = itemCommands.map(
          (command) => (
            <Button
              onClick={
                (event) => {
                  event.stopPropagation();
                  command.handler(phone);
                }
              }
              variant='secondary'
            >
              {command.icon || command.text}
            </Button>
          ),
        );
      }
      return (
        <ListGroup.Item
          key={phone.id}
          active={isActive}
          action={!isActive}
          onClick={() => {
            if (onItemSelected) {
              onItemSelected(phone);
            }
          }}
        >
          <div className='phone-view'>
            <div className='phone-detail'>
              <span className='model'>{phone.model}</span>
              <span className='detail'>{phone.description}</span>
            </div>
            <div className='phone-price'>
              $
              {phone.price}
            </div>
            <div className='phone-commands'>
              {itemButtons}
            </div>
          </div>
        </ListGroup.Item>
      );
    },
  );

  let commands = null;
  if (listCommands && listCommands.length > 0) {
    const buttons = listCommands.map(
      (command) => <Button onClick={command.handler}>{command.text}</Button>,
    );
    commands = (
      <div className='list-commands'>
        {buttons}
      </div>
    );
  }

  return (
    <section className='items'>
      <h2>Phones</h2>
      <ListGroup>
        {phoneItems}
      </ListGroup>

      {commands}
    </section>
  );
}

export { PhoneList };
