import React from 'react';
import { PlanComparison } from '../PlanComparison';

function PhonePlanController(props) {
  const { phone } = props;

  return (
    <>
      <PlanComparison
        key={phone.id}
        phone={phone}
      />
    </>
  );
}

export { PhonePlanController };
