import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { StarFill } from 'react-bootstrap-icons';
import { ItemCommands } from './ItemCommands';
import { PlanDetail } from './PlanDetail';

// PhonePlanTable displays plans to the associated phone.
function PhonePlanTable(props) {
  const { plans, listCommands, itemCommands } = props;
  const priceDecimal = 2;

  const headers = [
    <th>{' '}</th>,
    <th>{' '}</th>,
    <th>Plan</th>,
    <th className='plan-price'>Total</th>,
    <th className='plan-price'>Overpay</th>,
  ];

  let minTotal = null;

  const planData = plans.map((plan) => {
    const total = ((+plan.price) * 24) + (+plan.phoneUpfront) + (+plan.phoneMonthly * 24);

    if (minTotal == null) {
      minTotal = total;
    } else {
      minTotal = Math.min(total, minTotal);
    }

    return {
      ...plan,
      total,
    };
  });

  const contents = planData.map(
    (plan) => {
      const cost = plan.total - minTotal;
      let best = null;
      if (cost === 0) {
        best = (
          <StarFill color='green' />
        );
      }

      let commands = null;
      if (itemCommands) {
        commands = (
          <ItemCommands
            item={plan}
            commands={itemCommands}
            className='item-commands'
          />
        );
      }

      return (
        <tr key={plan.id}>
          <td width='1%'>{best}</td>
          <td width='2%'>
            {commands}
          </td>
          <td><PlanDetail.View plan={plan} /></td>
          <td className='plan-price'>{(plan.total).toFixed(priceDecimal)}</td>
          <td className='plan-price'>{(cost).toFixed(priceDecimal)}</td>
        </tr>
      );
    },
  );

  let listButtons = null;
  if (listCommands && listCommands.length > 0) {
    const buttons = listCommands.map(
      (command) => <Button onClick={command.handler}>{command.text}</Button>,
    );
    listButtons = (
      <div className='list-commands'>
        {buttons}
      </div>
    );
  }

  return (
    <>
      <Table striped bordered hover>
        <thead>
          {headers}
        </thead>
        <tbody>
          {contents}
        </tbody>
      </Table>

      {listButtons}
    </>
  );
}

export { PhonePlanTable };
